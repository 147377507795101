import useTrigger from "./useTrigger";

/**
 * useEscape
 * @param {Function} callback
 * @param {Array} conditions
 * @description
 * This hook is used to trigger a callback function when the escape key is pressed.
 * The conditions array is used to determine if the callback should be triggered.
 */

export default function useEscape(callback, conditions = []) {
  useTrigger("keydown", (e) => {
    if (e.key === "Escape" && conditions.every((c) => c)) {
      e.stopPropagation();
      callback();
    }
  });
}
