import { useCallback, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";

/**
 * Custom hook for using Apollo's useLazyQuery with BUP's recommended setup.
 * @param {gql} query - The GraphQL query to run.
 * @param {Object} variables - The query variables to use.
 * @param {string} fetchPolicy - The fetch policy to use (default is 'cache-and-network').
 * @returns {array} - An array containing the onSubmit function and the query response object.
 */
function useBupLazy(query, fetchPolicy = "cache-and-network") {
  // Call useLazyQuery with the provided query.
  const [trigger, { called, data, loading, error }] = useLazyQuery(query);
  // Create local state for the response object and a flag for whether the query has loaded.
  const [response, setResponse] = useState({});
  const [loaded, setLoaded] = useState(false);

  // Use useEffect to update the response state and set the loaded flag when the query finishes loading.
  useEffect(() => {
    if (!loading && data) {
      // The response data is an object with a single key containing the actual data we want to use.
      // Use Object.values() to extract the data object, and then get the first item in the array ([0]).
      setResponse(Object.values(data)?.[0]);
      setLoaded(true);
    }
  }, [data, loading, trigger, query]);

  // Define the onSubmit function as a useCallback to prevent unnecessary re-renders.
  const onSubmit = useCallback(
    (variables = {}, options = {}) => {
      // Call the trigger function with the provided variables and options.
      trigger({ fetchPolicy, variables, ...options });
    },
    [fetchPolicy, trigger]
  );

  // Return the onSubmit function and the query response object.
  return [onSubmit, { called, data: response, loading, loaded, error }];
}

export default useBupLazy;
