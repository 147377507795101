import { gql } from "@apollo/client";

const PROFILE_CORE = gql`
  fragment ProfileCore on UserProfile {
    _id
    affiliations
    info {
      birth
      commune
      firstName
      gender
      hasProfilePic
      lastName
      phone
      region
    }
    interests {
      _id
    }
    status {
      banned
      deleted
      isAdmin
      isPremium
    }
  }
`;

export default PROFILE_CORE;
