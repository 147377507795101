/**
 * Calculate age based on a given birthday date.
 *
 * @param {string} birthday - The birthday date in string format (e.g., "YYYY-MM-DD").
 * @returns {number} The calculated age in years.
 */
export default function calculateAge(birthday) {
  const ageDifMs = Date.now() - new Date(birthday).getTime();
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}
