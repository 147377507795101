import ANSWER_SURVEY from "./profile/answerSurvey";
import APPLY_DISCOUNT from "./payment/applyDiscount";
import ASSOCIATE_CARD from "./payment/associateCard";
import CANCEL_SUBSCRIPTION from "./payment/cancelSubscription";
import CREATE_SUBSCRIPTION from "./payment/createSubscription";
import DELETE_SUBSCRIPTION from "./payment/deleteSubscription";
import DELETE_USER_PROFILE from "./settings/deleteUserProfile";
import GET_USER_PHOTO from "./profile/getUserPhoto";
import READ_NOTIFICATION from "./settings/readNotification";
import REDEEM_REFERRAL from "./profile/redeemReferral";
import UNLINK_CARD from "./payment/unlinkCard";
import UPDATE_ONBOARDING from "./profile/updateOnBoarding";
import UPDATE_SETTINGS from "./settings/updateSettings";
import UPDATE_SUBSCRIPTION from "./payment/updateSubscription";
import UPDATE_USER_INTERESTS from "./profile/updateUserInterests";
import UPDATE_USER_PROFILE from "./profile/updateUserProfile";
import VALIDATE_DISCOUNT from "./payment/validateDiscount";

export {
  ANSWER_SURVEY,
  APPLY_DISCOUNT,
  ASSOCIATE_CARD,
  CANCEL_SUBSCRIPTION,
  CREATE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION,
  DELETE_USER_PROFILE,
  GET_USER_PHOTO,
  READ_NOTIFICATION,
  REDEEM_REFERRAL,
  UNLINK_CARD,
  UPDATE_ONBOARDING,
  UPDATE_SETTINGS,
  UPDATE_SUBSCRIPTION,
  UPDATE_USER_INTERESTS,
  UPDATE_USER_PROFILE,
  VALIDATE_DISCOUNT,
};
