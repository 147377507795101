/**
 * Join elements of an array with commas and "and" for the last element.
 *
 * @param {Array} array - The array of elements to join.
 * @returns {string} The joined string with commas and "y" for the last element.
 */
export default function joinElements(array) {
  if (!array) return;

  if (array.length > 1)
    return `${array.slice(0, -1).join(", ")} y ${array.slice(-1)[0]}`;

  return array[0];
}
