import { gql } from "@apollo/client";
import { EVENT_CORE } from "fragments";

const GET_PAST_EVENTS = gql`
  query GetPastEvents($limit: Int) {
    getUserPastEvents(limit: $limit) {
      total
      instances {
        ...EventCore
        ratings {
          average
          userRatings {
            ref
            value
          }
        }
      }
    }
  }
  ${EVENT_CORE}
`;

export default GET_PAST_EVENTS;
