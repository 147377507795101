import { useLazyQuery, useMutation } from "gql";
import { GET_NOTIFICATIONS } from "queries/account";
import { READ_NOTIFICATION } from "mutations/account";
import { useEffect, useState } from "react";

function useNotifications(uid) {
  const [readNotification] = useMutation(READ_NOTIFICATION);
  const [getNotifications, { called, data, loading, error }] =
    useLazyQuery(GET_NOTIFICATIONS);

  const [notifications, setNotifications] = useState([]);
  const [unread, setUnread] = useState(0);

  const handleGetNotifications = () => {
    if (!loading) setTimeout(() => getNotifications({ id: uid }), 500);
  };

  const handleReadNotifications = () => {
    readNotification({
      notificationIds: notifications
        .filter((notification) => notification.status === "unread")
        .map((notification) => notification._id),
    });
    setNotifications((notifications) =>
      notifications.map((x) => ({ ...x, status: "read" }))
    );
    setUnread(0);
  };

  const handleReadNotification = (id) => {
    readNotification({ notificationIds: [id] });
    setNotifications((notifications) =>
      notifications.map((notification) =>
        notification._id === id
          ? { ...notification, status: "read" }
          : notification
      )
    );
    setUnread((unread) => Math.max(unread - 1, 0));
  };

  useEffect(() => {
    if (!called && uid) getNotifications({ id: uid });
  }, [called, uid, getNotifications]);

  useEffect(() => {
    if (data?.instances && !loading) {
      setNotifications(data.instances);
      setUnread(
        data.instances.filter(
          (notification) => notification.status === "unread"
        ).length || 0
      );
    }
  }, [data, loading]);

  return {
    data: notifications,
    error,
    handleGetNotifications,
    handleReadNotifications,
    handleReadNotification,
    loading,
    unread,
  };
}

export default useNotifications;
