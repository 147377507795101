"use client";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Roboto } from "next/font/google";

export const roboto = Roboto({
  weight: ["300", "400", "500", "700"],
  style: ["normal", "italic"],
  subsets: ["latin"],
  display: "swap",
});

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  palette: {
    primary: {
      dark: "#003180",
      light: "#336bc6",
      main: "#0047b8",
      contrastText: "#ffffff",
    },
    secondary: {
      dark: "#0e4874",
      light: "#4386b8",
      main: "#1568a7",
      contrastText: "#ffffff",
    },
    tertiary: {
      dark: "#0074a0",
      light: "#33b8ea",
      main: "#00a7e5",
      contrastText: "#000000",
    },
    premium: {
      dark: "#987316",
      light: "#e1b74c",
      main: "#daa520",
      contrastText: "#000000",
    },
    success: {
      dark: "#0b6258",
      light: "#40a397",
      main: "#118c7e",
      contrastText: "#ffffff",
    },
    danger: {
      dark: "#a32121",
      light: "#e04f4f",
      main: "#d32f2f",
      contrastText: "#ffffff",
    },
    warning: {
      dark: "#b26a00",
      light: "#ff9800",
      main: "#ff9800",
      contrastText: "#ffffff",
    },
    info: {
      dark: "#0069c0",
      light: "#2196f3",
      main: "#2196f3",
      contrastText: "#ffffff",
    },
    disabled: {
      dark: "#616161",
      light: "#9e9e9e",
      main: "#9e9e9e",
      contrastText: "#ffffff",
    },
  },
  status: {
    active: "#0047b8",
    danger: "#d32f2f",
    disabled: "#9e9e9e",
    focus: "#ff5722",
    hover: "#e0e0e0",
    info: "#2196f3",
    pending: "#ffc107",
    success: "#118c7e",
    warning: "#ff9800",
  },
});

export function MuiTheme({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
