import { GOOGLE } from "contexts/data/consts";
import str from "utils/string";

/**
 * Complete an external URL by adding "http://" to it if it doesn't already start with "http://" or "https://".
 *
 * @param {string} url - The URL to complete.
 * @returns {string} The completed URL.
 */
function completeExternalUrl(url) {
  if (!url) return "";

  // Check if the URL already starts with "http://" or "https://"
  if (url.startsWith("http://") || url.startsWith("https://")) {
    return url;
  }

  // Define a pattern to check if the URL resembles a domain (e.g., example.com)
  const pattern = /^(?:http:\/\/|https:\/\/)?(?:[\w]+\.)+[a-zA-Z]{2,7}$/;

  // Check if the URL matches the pattern
  const match = url.match(pattern);

  // If the URL matches the pattern, add "http://" to it
  if (match && match.length > 0) {
    return "http://" + url;
  }

  // If the URL doesn't match the pattern or start with "http://" or "https://", return an empty string
  return "";
}

/**
 * Convert a video sharing platform URL into an embeddable video URL with customized parameters.
 *
 * @param {string} url - The video sharing platform URL.
 * @returns {string|null} The embeddable video URL or null if the input URL is not supported.
 */
function convertToEmbedLink(url) {
  const youtubeWatchLinkRegex = /youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/;
  const youtubeShortLinkRegex = /youtu\.be\/([a-zA-Z0-9_-]+)/;
  const vimeoLinkRegex = /vimeo\.com\/(\d+)/;

  let videoId;

  // Check if the input URL matches a YouTube watch URL pattern
  if (youtubeWatchLinkRegex.test(url)) {
    videoId = url.match(youtubeWatchLinkRegex)[1];
    return `https://www.youtube.com/embed/${videoId}?controls=0&modestbranding=1&showinfo=0`;
  }
  // Check if the input URL matches a YouTube short link pattern
  else if (youtubeShortLinkRegex.test(url)) {
    videoId = url.match(youtubeShortLinkRegex)[1];
    return `https://www.youtube.com/embed/${videoId}?controls=0&modestbranding=1&showinfo=0`;
  }
  // Check if the input URL matches a Vimeo link pattern
  else if (vimeoLinkRegex.test(url)) {
    videoId = url.match(vimeoLinkRegex)[1];
    return `https://player.vimeo.com/video/${videoId}`;
  }

  // If the input URL doesn't match any supported patterns, return null
  return null;
}

/**
 * Find and translate Google Place types into a more human-readable format.
 *
 * @param {object} param0 - An object containing the `types` property, which is an array of Google Place types.
 * @returns {string|null} The translated place type or null if the input is not valid.
 */
function findGooglePlaceTypes({ types }) {
  if (!types) return null;

  // Get the list of Google Place types and their translations
  const placeTypes = Object.keys(GOOGLE.placeTypesTranslation);

  // Find the index of the first matching type in the list
  const index = placeTypes.findIndex((x) => types.includes(x));

  // If a matching type is found, return its translated version in a capitalized format
  if (index === -1) return null;
  return str.capitalize(GOOGLE.placeTypesTranslation[placeTypes[index]]);
}

/**
 * Format URLs in text by replacing them with HTML <a> tags for clickable links.
 *
 * @param {string} text - The input text that may contain URLs.
 * @returns {string} The formatted text with clickable links.
 */
function formatLinksInText(text) {
  // Regular expression to match links
  const linkRegex = /(?:https?|ftp):\/\/[\n\S]+/g;

  // Regular expression to match URLs within links
  const urlRegex = /https?:\/\/(www\.)?[\w-]+\.[\w./?=&#%'-]+/g;

  // Create a copy of the input text
  let formattedText = text.slice();

  // Find all links in the text
  const links = formattedText.match(linkRegex);

  if (links && links.length > 0) {
    for (const link of links) {
      // Find all URLs within the link
      const urls = link.match(urlRegex);

      if (urls && urls.length > 0) {
        for (const url of urls) {
          // Remove the "http://", "https://", or "www." from the URL for display
          const formattedUrl = url.replace(/^https?:\/\/(www\.)?/, "");

          // Create a replacement HTML <a> tag for the URL
          const replacement = `<a href="${url}" target="_blank">${formattedUrl}</a>`;

          // Replace the URL in the formatted text with the HTML <a> tag
          formattedText = formattedText.replace(url, replacement);
        }
      }
    }
  }

  return formattedText;
}

/**
 * Extracts and returns the file extension from a given file name.
 * @param {String} fileName - The name of the file, including its extension.
 * @returns {String} - The file extension as a string.
 */
function getImageExtension(fileName) {
  return fileName.split(".").pop();
}

/**
 * Constructs an image URL based on the provided file name.
 * @param {String} fileName - The name of the image file.
 * @returns {String} - A URL pointing to the specified image file.
 */
function getImageUrl(fileName) {
  const fixedFileName = fileName.startsWith("/") ? fileName.slice(1) : fileName;
  return `/${fixedFileName}`;
}

/**
 * Generates a WhatsApp URL to open WhatsApp with a specified phone number and optional pre-composed message.
 * @param {String} phoneNumber - The phone number to open in WhatsApp.
 * @param {String} [message] - An optional pre-composed message.
 * @returns {String} - A WhatsApp URL.
 */
function getWhatsappUrl(phoneNumber, message) {
  return `https://wa.me/${phoneNumber}${message ? `?text=${message}` : ""}`;
}

/**
 * Generates a Google Maps URL for directions to a specified location using latitude and longitude.
 * @param {Object} location - An object containing latitude (lat) and longitude (lng) coordinates.
 * @returns {String} - A Google Maps URL for directions to the specified location.
 */
function generateGoogleMapsUrl({ lat, lng }) {
  if (!lat && !lng) return "";

  const baseUrl = "https://www.google.com/maps/dir/?api=1";
  const coordinatesParam = `destination=${lat},${lng}`;

  const params = [coordinatesParam].filter(Boolean).join("&");
  const googleMapsLink = `${baseUrl}&${params}`;

  return googleMapsLink;
}

/**
 * Generates an embedded Google Maps URL for displaying a location on a map.
 * @param {Object} options - An object containing address, latitude (lat), longitude (lng), and zoom level (zoom).
 * @returns {String} - An embedded Google Maps URL for displaying the specified location.
 */
function generateGoogleMapsEmbed({ address, lat, lng, mapUrl, name, zoom }) {
  if (!address && !name) return mapUrl;

  const key = process.env.GOOGLE_MAPS_API_KEY;
  const queryParams = new URLSearchParams({
    key,
    q: address || name,
    center: `${lat},${lng}`,
    zoom,
  });

  return `https://www.google.com/maps/embed/v1/place?${queryParams}`;
}

/**
 * Generates a URL by appending query parameters to an existing URL.
 * @param {Object} options - An object containing the base URL (url) and query parameters (params).
 * @returns {String} - The generated URL with appended query parameters.
 */
function generateUrlWithParams({ url, params }) {
  const queryParams = new URLSearchParams(params);
  return url + "/?" + queryParams;
}

/**
 * Generates a Google Meet URL for remote meetings.
 * @param {String} format - The meeting format (e.g., "remote").
 * @returns {String|null} - A Google Meet URL for remote meetings or null if the format is not "remote."
 */
function generateGoogleMeetUrl({ format }) {
  if (!format || format !== "remote") return null;
  return "https://meet.google.com/new";
}

/**
 * Formats a phone number to a more readable format with an optional prefix.
 * @param {String|Number} phoneNumber - The phone number to format.
 * @param {Boolean} includePrefix - Whether to include a prefix (e.g., "+") before the phone number.
 * @returns {String} - The formatted phone number.
 */
function toReadablePhone(phoneNumber, includePrefix = true) {
  const phoneParsed = phoneNumber?.toString();
  const prefix = includePrefix ? "+" : "";

  return phoneParsed
    ? `${prefix}${phoneParsed?.slice(0, 3)} ${phoneParsed?.slice(
        3,
        7
      )} ${phoneParsed?.slice(7, 11)}`
    : "-";
}

/**
 * Updates the class list of the body element based on a condition.
 * @param {String} className - The name of the CSS class to add or remove.
 * @param {Boolean} condition - The condition to determine whether to add or remove the class.
 */
function updateClassList(className, condition) {
  if (condition) {
    if (!document.body.className.includes(className)) {
      document.body.classList.add(className);
    }
  } else {
    document.body.classList.remove(className);
  }
}

/**
 * Validates an image file based on its name and size.
 * @param {File} file - The image file to validate.
 * @returns {Boolean} - Whether the file is a valid image.
 */
function validateImage(file) {
  if (!file?.name) return false;
  const validExtensions = /\.(jpg|jpeg|png|gif|bmp|svg|tiff|tif|raw|webp)$/i;
  const maxSize = 15 * 1024 * 1024; // 15 MB in bytes
  return validExtensions.test(file.name) && file.size <= maxSize;
}

/**
 * Validates an image URL based on its file extension and URL format.
 * @param {String} url - The image URL to validate.
 * @returns {Boolean} - Whether the URL is a valid image URL.
 */
function validateImageUrl(url) {
  const validExtensions = /\.(jpg|jpeg|png|gif|bmp|svg|tiff|tif|raw|webp)$/i;
  const urlIsValid = validateUrl(url);
  const isImage = validExtensions.test(url);
  return urlIsValid && isImage;
}

/**
 * Validates an event URL based on its format and network URL.
 * @param {String} url - The event URL to validate.
 * @returns {Boolean} - Whether the URL is a valid event URL.
 */
function validateEventUrl(url) {
  if (!validateUrl(url) || !url.includes(process.env.NETWORK_URL)) return false;

  const fixedUrl = url.slice(-1) === "/" ? url.slice(0, -1) : url;
  const data = fixedUrl.split("/").slice(-2);
  if (data[0] !== "event" || data[1].length !== 24) return false;

  return true;
}

/**
 * Validates a URL based on its format (http, https, or ftp).
 * @param {String} url - The URL to validate.
 * @returns {Boolean} - Whether the URL is a valid URL.
 */
function validateUrl(url) {
  const regex = /^(ftp|http|https):\/\/[^ "]+$/;
  return regex.test(url);
}

const helpers = {
  completeExternalUrl,
  convertToEmbedLink,
  findGooglePlaceTypes,
  formatLinksInText,
  generateGoogleMapsEmbed,
  generateGoogleMapsUrl,
  generateGoogleMeetUrl,
  generateUrlWithParams,
  getImageExtension,
  getImageUrl,
  getWhatsappUrl,
  toReadablePhone,
  updateClassList,
  validateEventUrl,
  validateImage,
  validateImageUrl,
  validateUrl,
};

export default helpers;
