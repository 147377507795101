/**
 * Concatenate multiple arrays into a single array while filtering out undefined and empty values.
 *
 * @param {...Array} arrays - Arrays to concatenate.
 * @returns {Array} A new concatenated array with undefined and empty values filtered out.
 */
export default function concatArray(...arrays) {
  // Use the spread operator to concatenate the arrays and filter out undefined and empty values
  return []
    .concat(...arrays)
    .filter((element) => element !== undefined && element !== "");
}
