import { gql } from "@apollo/client";
import { PROFILE_CORE } from "fragments";

const GET_FRIEND_LIST = gql`
  query GetFriendList($ids: [ID], $limit: Int, $offset: Int) {
    getUsersProfiles(ids: $ids, limit: $limit, offset: $offset) {
      total
      instances {
        ...ProfileCore
      }
    }
  }
  ${PROFILE_CORE}
`;

export default GET_FRIEND_LIST;
