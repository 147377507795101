import generateArray from "./generateArray";

/**
 * Repeat a React component a specified number of times.
 *
 * @param {React.Component} Component - The React component to repeat.
 * @param {number} times - The number of times to repeat the component.
 * @returns {Array} An array of React components repeated 'times' times.
 */
export default function repeatComponent(Component, times) {
  return generateArray(times).map((_, i) => <Component key={i} />);
}
