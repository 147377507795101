import { useLazyQuery } from "gql";
import { GET_VERIFIED_LIST } from "queries/account";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useUserInfo } from "store";
import obj from "utils/object";
import { METER } from "consts";

function useProfileMeter(uid) {
  const [checklist, setList] = useState([]);
  const [getVerifiedList, { called, data, loading, error }] =
    useLazyQuery(GET_VERIFIED_LIST);

  const user = useUserInfo();

  const info = useMemo(() => {
    return obj.elementsPicker(user, [
      "email",
      "phone",
      "biography",
      "hasProfilePic",
      "isPremium",
    ]);
  }, [user]);

  const pending = useMemo(
    () => checklist.reduce((t, x) => t + (x.status === "pending" ? 1 : 0), 0),
    [checklist]
  );

  const refreshVerifiedList = () => {
    if (!loading && uid) {
      getVerifiedList({
        id: uid,
      });
    }
  };

  const getVerify = useCallback(
    (name) => {
      const item = name.split(".");
      return item.length > 1
        ? data.verifiedList[item[0]][item[1]]
        : data.verifiedList[name];
    },
    [data.verifiedList]
  );

  const checker = useCallback(
    (name) =>
      checklist.find((item) => item.name === name)?.status === "completed",
    [checklist]
  );

  useEffect(() => {
    if (called && !loading && !user.loading && pending) {
      refreshVerifiedList();
    }
  }, [info, pending]);

  useEffect(() => {
    if (!called) refreshVerifiedList();
  }, [called, uid]);

  useEffect(() => {
    if (data.verifiedList && !loading) {
      setList(
        [
          ...METER.verifyList.map((item) => ({
            name: item.name,
            status:
              item.skip || getVerify(item.verify)
                ? METER.status.completed
                : METER.status.pending,
          })),
          ...METER.userInfo.map((item) => ({
            name: item.name,
            status: item.verify.every((i) => info[i])
              ? METER.status.completed
              : METER.status.pending,
          })),
        ].sort((a, b) => obj.sortBy(a, b, "status"))
      );
    }
  }, [data, loading, info]);

  return {
    checker,
    checklist,
    refreshVerifiedList,
    verifiedList: data.verifiedList || {},
    pending,
    loading,
    error,
  };
}

export default useProfileMeter;
