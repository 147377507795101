import { useLazyQuery } from "gql";
import { GET_USER_STORE } from "queries/account";
import { useEffect } from "react";
import { setUserStore, useUserStore } from "store";
import obj from "utils/object";

function useDatabase(userId) {
  const userStore = useUserStore();
  const [refreshUserStore, { called, data: database, loading }] =
    useLazyQuery(GET_USER_STORE);

  useEffect(() => {
    if (userId) refreshUserStore({ id: userId });
  }, [userId, GET_USER_STORE]);

  useEffect(() => {
    if (database._id) {
      setUserStore((store) => ({
        events: {
          ...store.events,
          going: obj.getIds(database?.events?.going),
          interested: obj.getIds(database?.events?.interested),
          history: obj.getIds(database?.events?.past),
        },
        friends: obj.getIds(database?.friends?.list),
        groups: {
          ...store.groups,
          joined: obj.getIds(database?.groups?.joined),
        },
        interests: obj.getIds(database?.interests),
        requests: {
          received: obj.getIds(database?.friends?.receivedRequest),
          sent: obj.getIds(database?.friends?.sentRequest),
        },
        referral: database.referralDetails,
        status: database.status,
      }));
    }
  }, [database, userId]);

  return {
    loading: !called && loading,
    refreshUserStore,
    userStore,
  };
}

export default useDatabase;
