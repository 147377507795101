import { gql } from "@apollo/client";

const GROUP_CORE = gql`
  fragment GroupCore on Group {
    _id
    createdAt
    info {
      about
      communes
      country
      name
      photoUrl
      region
      whatsappUrl
    }
    interests {
      _id
    }
    members {
      _id
    }
    published
    slots
  }
`;

export default GROUP_CORE;
