import { gql } from "@apollo/client";
import { PROFILE_CORE } from "fragments";

const GET_ADMIN_LIST = gql`
  query GetAdminList($isAdmin: Boolean, $limit: Int) {
    getUsersProfiles(isAdmin: $isAdmin, limit: $limit) {
      total
      instances {
        ...ProfileCore
      }
    }
  }
  ${PROFILE_CORE}
`;

export default GET_ADMIN_LIST;
