import { gql } from "@apollo/client";
import { PROFILE_CORE } from "fragments";

const GET_FRIEND_SUGGEST = gql`
  query GetFriendSuggest($userProfileId: ID!, $limit: Int) {
    getFriendSuggestions(userProfileId: $userProfileId, limit: $limit) {
      total
      instances {
        ...ProfileCore
      }
    }
  }
  ${PROFILE_CORE}
`;

export default GET_FRIEND_SUGGEST;
