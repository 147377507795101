/**
 * Create a dictionary (object) from an array of objects.
 *
 * @param {Array} array - The array of objects.
 * @param {string|boolean} a - The key property to use in the dictionary or `true` to use 'id'.
 * @param {string|boolean} b - The value property to use in the dictionary or `true` to use the rest of the object.
 * @returns {Object} A dictionary object created from the array.
 */
export default function dictionary(array, a, b) {
  return Object.fromEntries(
    a && b
      ? array.map((x) => [x[a], x[b]])
      : array.map(({ id, ...data }) => [
          a === true ? id.toUpperCase() : id,
          data,
        ])
  );
}
