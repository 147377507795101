/**
 * Repeat a text string multiple times.
 *
 * @param {string} text - The text to repeat.
 * @param {number} times - The number of times to repeat the text.
 * @returns {string} The repeated text.
 */
export default function repeat(text, times) {
  if (typeof text !== "string") {
    return "";
  }

  return text.toString().repeat(times);
}
