import moment from "moment";
import calendarToTime from "./calendarToTime";

/**
 * Convert date and time strings to a database-compatible date-time format.
 *
 * @param {string} date - The date string in "YYYY-MM-DD" format.
 * @param {string} time - The time string in "HH:mm" format.
 * @returns {object} A JavaScript date object with combined date and time.
 */
export default function toDBDateTime(date, time) {
  return moment(date + "T" + calendarToTime(time) + "Z")._d;
}
