import { gql } from "@apollo/client";

const UPDATE_ONBOARDING = gql`
  mutation ModifyUserProfile($id: ID!, $status: ModifyUserProfileStatusInput) {
    modifyUserProfile(_id: $id, status: $status) {
      status {
        onboardingCompleted
      }
    }
  }
`;

export default UPDATE_ONBOARDING;
