import { gql } from "@apollo/client";

const UNLINK_CARD = gql`
  mutation UnlinkCard($id: ID!) {
    unlinkCard(_id: $id) {
      _id
    }
  }
`;

export default UNLINK_CARD;
