import { gql } from "@apollo/client";

const GET_NOTIFICATIONS = gql`
  query GetNotificationsByUserId($id: ID!) {
    getNotificationsByUserId(_id: $id) {
      instances {
        _id
        body
        status
        deliverAt
        eventId
        groupId
        notificationTemplate
        senderId
      }
      offset
      limit
      total
    }
  }
`;

export default GET_NOTIFICATIONS;
