import moment from "moment";
import toLocaleDate from "./toLocaleDate";

/**
 * Check if an date is an upcoming event.
 * @param {object} date - The date object to check.
 * @returns {boolean} `true` if the date is upcoming; otherwise, `false`.
 */
export default function isUpcomingDate(date) {
  if (!date) return false;
  const endDate = toLocaleDate(date);
  return !endDate.isBefore(moment());
}
