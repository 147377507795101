import { gql } from "@apollo/client";

const GET_GROUP_PICTURE = gql`
  query GetGroupPicture($id: ID) {
    getGroup(_id: $id) {
      _id
      info {
        photoUrl
      }
    }
  }
`;

export default GET_GROUP_PICTURE;
