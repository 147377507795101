import { DATE } from "consts";
import toLocaleDate from "./toLocaleDate";
import str from "utils/string";

/**
 * Format a short date string with day, month, year, and time.
 *
 * @param {string} dateString - The date string to format.
 * @returns {string} The formatted short date string (e.g., "Sun 1 January 2023, 15:30 hrs").
 */
export default function formatShortDate(dateString) {
  const date = toLocaleDate(dateString);

  const dayOfWeek = DATE.daysTranslations[date.format("dddd")]?.slice(0, 3);
  const dayOfMonth = date.format("D");
  const month = DATE.monthTranslations[date.format("MMMM")];
  const year = date.format("YYYY");
  const time = `${date.format("HH:mm")} hrs`;

  return `${str.capitalize(dayOfWeek)} ${dayOfMonth} ${month} ${year}, ${time}`;
}
