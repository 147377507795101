import globalStore from "./model";

export const setLoading = (loading) => {
  globalStore({ ...globalStore(), loading });
};

export const setError = (error) => {
  globalStore({ ...globalStore(), error });
};

export const setRefreshRoute = (refreshRoute) => {
  globalStore({ ...globalStore(), refreshRoute });
};
