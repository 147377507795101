import moment from "moment";

/**
 * Convert a date string to a moment.js date object.
 *
 * @param {string} dateString - The date string to convert.
 * @param {boolean} isTimestamp - Set to `true` if `dateString` is a timestamp.
 * @returns {object} A moment.js date object.
 */
export default function toLocaleDate(dateString, isTimestamp = false) {
  if (typeof dateString !== "string" || isTimestamp) return moment(dateString);
  return moment(dateString?.replace(/Z/g, ""));
}
