import { networkStore } from "./model";

export const getNetwork = () => {
  return networkStore();
};

export const getEvents = () => {
  return networkStore().events;
};

export const getGroups = () => {
  return networkStore().groups;
};

export const getInterests = () => {
  return networkStore().interests;
};

export const getPeople = () => {
  return networkStore().people;
};

export const getPicture = () => {
  return networkStore().picture;
};
