import { gql } from "@apollo/client";

const ANSWER_SURVEY = gql`
  mutation CreateLonelinessSurvey(
    $userProfileId: ID!
    $answer1: Int!
    $answer2: Int!
    $answer3: Int!
  ) {
    createLonelinessSurvey(
      userProfileId: $userProfileId
      answer1: $answer1
      answer2: $answer2
      answer3: $answer3
    ) {
      _id
    }
  }
`;

export default ANSWER_SURVEY;
