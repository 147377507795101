import { gql } from "@apollo/client";

const UPDATE_SUBSCRIPTION = gql`
  mutation UpdateSubscription(
    $id: ID!
    $subscriptionManagement: SubscriptionManagementInput
  ) {
    modifyUserAccounting(
      _id: $id
      subscriptionManagement: $subscriptionManagement
    ) {
      _id
    }
  }
`;

export default UPDATE_SUBSCRIPTION;
