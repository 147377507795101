import { userInfo, userStore } from "./model";

export const setUserInfo = (info) => {
  userInfo({ ...userInfo(), ...info });
};

export const setUserStore = (update) => {
  const current = userStore();
  const store = typeof update === "function" ? update(current) : update;
  userStore({ ...current, ...store });
};

export const setIsPremium = ({ isPremium }) => {
  userInfo({ ...userInfo(), isPremium });
};

export const setPhotoUrl = ({ photoUrl }) => {
  userInfo({ ...userInfo(), photoUrl });
};
