/**
 * Count the number of common elements between two arrays.
 *
 * @param {Array} arr1 - The first array.
 * @param {Array} arr2 - The second array.
 * @returns {number} The count of common elements between the two arrays.
 */
export default function countCommon(arr1 = [], arr2 = []) {
  let common = 0;

  // Check if both arrays have elements
  if (arr1?.length && arr2?.length) {
    // Iterate through the first array and count common elements in the second array
    for (let i = 0; i < arr1.length; i++) {
      if (arr2.includes(arr1[i])) {
        common++;
      }
    }
  }

  return common;
}
