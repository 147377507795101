import { DATE } from "consts";

/**
 * Add a specified number of hours to a given date.
 *
 * @param {Date} date - The date to which hours will be added.
 * @param {number} hours - The number of hours to add.
 * @returns {Date} A new Date object with the added hours.
 */
export default function addHoursToDate(date, hours) {
  const totalMillisecondsToAdd = hours * DATE.hourInMilliseconds;
  return new Date(date.getTime() + totalMillisecondsToAdd);
}
