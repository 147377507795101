import str from "utils/string";
import { DATE } from "consts";

/**
 * Convert a date string to a short time format (e.g., "5h" or "30m ago").
 *
 * @param {string} dateString - The date string to convert.
 * @param {boolean} useLongFormat - Set to `true` to use long format (e.g., "5 hours ago").
 * @param {boolean} isTimestamp - Set to `true` if `dateString` is a timestamp.
 * @returns {string} The short time format string.
 */
export default function toShortTime(dateString, useLongFormat = false) {
  const millisecondsAgo = Date.now() - new Date(dateString).getTime();

  const timeUnits = [
    { unit: "año", short: "y", base: DATE.yearInMilliseconds },
    { unit: "semana", short: "w", base: DATE.weekInMilliseconds },
    { unit: "día", short: "d", base: DATE.dayInMilliseconds },
    { unit: "hora", short: "h", base: DATE.hourInMilliseconds },
    { unit: "minuto", short: "m", base: DATE.minuteInMilliseconds },
    { unit: "segundo", short: "s", base: DATE.secondInMilliseconds },
  ];

  for (let i = 0; i < timeUnits.length; i++) {
    const unit = timeUnits[i];
    const floor = Math.floor(millisecondsAgo / unit.base);

    if (floor > 0) {
      return useLongFormat
        ? str.pluralize(floor, unit.unit)
        : `${floor}${unit.short}`;
    }
  }

  return "0s";
}
