import { gql } from "@apollo/client";

const GET_ACCOUNTING = gql`
  query GetUserAccounting($id: String!) {
    getUserAccounting(_id: $id) {
      _id
      flow {
        associatedCard {
          associateCardUrl
          cardType
          lastCardDigits
          status
        }
        customerId
        subscriptions {
          currentSubscription
          subscriptionHistory
        }
      }
      info {
        email
        name
        rut
      }
      subscriptionManagement {
        resumeSubscription
        changePlan {
          newPlan
          requested
        }
      }
    }
  }
`;

export default GET_ACCOUNTING;
