import { gql } from "@apollo/client";

const GET_USER_SURVEY = gql`
  query GetUserSurvey($userProfileId: ID, $surveyName: String) {
    getSurveys(userProfileId: $userProfileId, surveyName: $surveyName) {
      instances {
        _id
        createdAt
      }
    }
  }
`;

export default GET_USER_SURVEY;
