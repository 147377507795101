import isValidDate from "./isValidDate";
import toLocaleDate from "./toLocaleDate";
import { DATE } from "consts";

/**
 * Format a date and time string, optionally converting a timestamp to a localized date and time format.
 *
 * @param {string} dateString - The date and time string to format.
 * @param {boolean} isTimestamp - Whether the input string is a timestamp.
 * @returns {string} The formatted date and time string (e.g., "1 de January, 15:30 hrs.").
 */
export default function formatDateTime(dateString, isTimestamp = false) {
  if (!isValidDate(dateString)) {
    return "";
  }

  const date = toLocaleDate(dateString, isTimestamp);
  const day = date.format("D");
  const month = DATE.monthTranslations[date.format("MMMM")];
  const time = date.format("HH:mm");

  return `${day} de ${month}, ${time} hrs.`;
}
