import { gql } from "@apollo/client";

const GET_USER_INTERESTS = gql`
  query GetUserInterests($id: String!) {
    getUserProfile(_id: $id) {
      interests {
        _id
        icon
        name
      }
    }
  }
`;

export default GET_USER_INTERESTS;
