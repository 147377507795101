import GET_ACCOUNTING from "./payment/getAccounting";
import GET_NOTIFICATIONS from "./settings/getNotifications";
import GET_SUBSCRIPTION from "./payment/getSubscription";
import GET_TEMPLATES from "./settings/getTemplates";
import GET_USER_BENEFIT from "./payment/getUserBenefit";
import GET_USER_INFO from "./profile/getUserInfo";
import GET_USER_INTERESTS from "./profile/getUserInterests";
import GET_USER_POSTS from "./profile/getUserPosts";
import GET_USER_STORE from "./profile/getUserStore";
import GET_USER_SURVEY from "./profile/getUserSurvey";
import GET_VERIFIED_LIST from "./profile/getVerifiedList";

export {
  GET_ACCOUNTING,
  GET_NOTIFICATIONS,
  GET_SUBSCRIPTION,
  GET_TEMPLATES,
  GET_USER_BENEFIT,
  GET_USER_INFO,
  GET_USER_INTERESTS,
  GET_USER_POSTS,
  GET_USER_STORE,
  GET_USER_SURVEY,
  GET_VERIFIED_LIST,
};
