import sortBy from "./sortBy";

/**
 * Get an array of options (key-value pairs) from an object and sort them by key.
 *
 * @param {Object} obj - The object to extract options from.
 * @returns {Array} An array of sorted key-value pairs from the object.
 */
export default function getOptions(obj) {
  if (!obj) return [];
  return Object.entries(obj).sort((a, b) => sortBy(a, b, 1));
}
