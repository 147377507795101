import { networkStore } from "./model";

export const setNetowrk = (network) => {
  networkStore.set(network);
};

export const updateNetwork = (update) => {
  const current = networkStore();
  const store = typeof update === "function" ? update(current) : update;
  networkStore({ ...current, ...store });
};

export const updateAdmins = (admins) => {
  networkStore({ ...networkStore(), admins });
};

export const updateAmbassadors = (ambassadors) => {
  networkStore({ ...networkStore(), ambassadors });
};

export const updateEvents = (events) => {
  networkStore({ ...networkStore(), events });
};

export const updateGroups = (groups) => {
  networkStore({ ...networkStore(), groups });
};

export const updateHistory = (history) => {
  networkStore({ ...networkStore(), history });
};

export const updatePeople = (people) => {
  networkStore({ ...networkStore(), people });
};

export const updateInterests = (interests) => {
  networkStore({ ...networkStore(), interests });
};

export const updatePictures = (pictures) => {
  networkStore({
    ...networkStore(),
    pictures: {
      ...networkStore().pictures,
      ...pictures,
    },
  });
};
