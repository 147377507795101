import { DATE } from "consts";
import toLocaleDate from "./toLocaleDate";

/**
 * Format a date string with day of week, day of month, and month.
 *
 * @param {string} dateString - The date string to format.
 * @returns {string} The formatted day of week string (e.g., "Domingo, 1 de Enero").
 */
export default function formatDayOfWeek(dateString) {
  const date = toLocaleDate(dateString);
  const dayOfWeek = DATE.daysTranslations[date.format("dddd")];
  const dayOfMonth = date.format("DD");
  const month = DATE.monthTranslations[date.format("MMMM")];
  return `${dayOfWeek}, ${dayOfMonth} de ${month}`;
}
