import { gql } from "@apollo/client";

const CREATE_SUBSCRIPTION = gql`
  mutation CreateSubscripti($userAccountingId: ID!, $planId: ID!) {
    createSubscription(userAccountingId: $userAccountingId, planId: $planId) {
      _id
      payments {
        amount
        paymentUrl
        status
      }
    }
  }
`;

export default CREATE_SUBSCRIPTION;
