/**
 * Replace an element at a specific index in an array.
 *
 * @param {Array} array - The original array.
 * @param {number} index - The index at which to replace the element.
 * @param {any} element - The element to replace with.
 * @returns {Array} A new array with the element at the specified index replaced.
 */
export default function replaceElement(array, index, element) {
  const res = [...array];
  res.splice(index, 1, element);
  return res;
}
