import { gql } from "@apollo/client";

const GET_VERIFIED_LIST = gql`
  query GetVerifiedList($id: String!) {
    getUserProfile(_id: $id) {
      verifiedList {
        addressInformation
        interests
        personalInformation
        survey
        tutorial {
          event
          friend
          group
        }
      }
    }
  }
`;

export default GET_VERIFIED_LIST;
