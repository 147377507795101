import CryptoJS from "crypto-js";

/**
 * A custom hook for encrypting and decrypting data using the AES encryption algorithm.
 *
 * @returns {{
 *   encrypt: (data: any) => string,
 *   decrypt: (data: string) => any | undefined
 * }} An object with functions for encrypting and decrypting data.
 */
function useCrypto() {
  // Retrieve the secret key from an environment variable
  const secretKey = process.env.FIREBASE_API_KEY;

  /**
   * Encrypts the provided data using the AES encryption algorithm.
   *
   * @param {any} data - The data to encrypt (can be of any type).
   * @returns {string} The encrypted data as a string.
   */
  function encrypt(data) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
  }

  /**
   * Decrypts the provided data using the AES encryption algorithm.
   *
   * @param {string} data - The encrypted data as a string.
   * @returns {any | undefined} The decrypted data (original type) or undefined if decryption fails.
   */
  function decrypt(data) {
    if (!data) return; // Return undefined if no data is provided
    const bytes = CryptoJS.AES.decrypt(data, secretKey);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decrypted); // Parse the decrypted data to its original type
  }

  /**
   * Hashes the provided data using the MD5 hashing algorithm.
   *
   * @param {any} data - The data to hash (can be of any type).
   * @returns {string} The hashed data as a string.
   */
  function md5Hash(data) {
    if (!data) return; // Return undefined if no data is provided
    else {
      const bytes = CryptoJS.MD5(data); // Hash the data
      return bytes.toString(CryptoJS.enc.Hex); // Return the hashed data as a string
    }
  }

  return {
    encrypt,
    decrypt,
    md5Hash,
  };
}

export default useCrypto;
