import { gql } from "@apollo/client";
import { GROUP_CORE } from "fragments";

const GET_SUGGEST_GROUPS = gql`
  query GetSuggestGroups($userProfileId: ID!, $limit: Int) {
    getGroupSuggestions(userProfileId: $userProfileId, limit: $limit) {
      total
      instances {
        ...GroupCore
      }
    }
  }
  ${GROUP_CORE}
`;

export default GET_SUGGEST_GROUPS;
