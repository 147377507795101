import { gql } from "@apollo/client";

const DELETE_USER_PROFILE = gql`
  mutation DeleteUser($userId: ID!) {
    deleteUser(userId: $userId) {
      userProfileDeleted
    }
  }
`;

export default DELETE_USER_PROFILE;
