import decodeToken from "contexts/auth/decodeToken";
import { useLazyQuery } from "gql";
import { POST_REFRESH } from "queries/session";
import { useEffect } from "react";
import {
  getRefreshToken,
  logout,
  setError,
  setLoading,
  useAuthStore,
} from "store";

const useRefreshToken = () => {
  const [updateToken, { data, loading, error }] = useLazyQuery(POST_REFRESH);
  const refreshToken = getRefreshToken();
  const { accessToken, userId } = useAuthStore();

  useEffect(() => {
    if (refreshToken && !accessToken) {
      updateToken({ refreshToken });
      setLoading(true);
    }
  }, [refreshToken, accessToken, updateToken]);

  useEffect(() => {
    if (refreshToken) {
      const timeout = setTimeout(
        async () => await updateToken({ refreshToken }),
        3300000
      );
      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [refreshToken, updateToken]);

  useEffect(() => {
    if (!loading) {
      if (data.accessToken) {
        const { accessToken, refreshToken } = data;
        decodeToken({ accessToken, refreshToken });
        setLoading(false);
      }
      if (error) {
        setError(true);
        setLoading(false);
        logout();
      }
    }
  }, [data, error, loading]);

  return { updateToken: () => updateToken({ refreshToken }), userId };
};

export default useRefreshToken;
