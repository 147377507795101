/**
 * Remove a specific element from an array.
 *
 * @param {Array} array - The original array.
 * @param {any} element - The element to remove from the array.
 * @returns {Array} A new array with the specified element removed.
 */
export default function removeElement(array, element) {
  const copy = array ? [...array] : [];

  if (array?.includes(element)) {
    copy.splice(
      copy.findIndex((id) => id === element),
      1
    );
  }

  return copy;
}
