import { DATE } from "consts";
import moment from "moment";

/**
 * Format a date string to display month and year.
 *
 * @param {string} dateString - The date string to format.
 * @returns {string} The formatted month and year string (e.g., "January 2023").
 */
export default function formatMonthYear(dateString) {
  const date = moment(dateString);
  const month = DATE.monthTranslations[date.format("MMMM")];
  const year = date.format("YYYY");

  return `${month} de ${year}`;
}
