import { gql } from "@apollo/client";
import { COMMENT_FIELDS, POST_FIELDS } from "fragments";

const GET_POSTS = gql`
  query GetPosts($to: ID, $limit: Int, $offset: Int, $target: String) {
    getPosts(to: $to, limit: $limit, offset: $offset, target: $target) {
      total
      instances {
        ...PostFields
        comments {
          ...CommentFields
        }
        pinned
        to
      }
    }
  }
  ${COMMENT_FIELDS}
  ${POST_FIELDS}
`;

export default GET_POSTS;
