/**
 * Update an array by adding or removing an element based on its existence.
 *
 * @param {Array} array - The original array.
 * @param {any} element - The element to add or remove from the array.
 * @returns {Array} A new array with the element added if it didn't exist or removed if it existed.
 */
export default function updateArray(array, element) {
  const copy = [...array];
  const index = copy.findIndex((x) => x === element);

  if (index === -1) copy.push(element);
  else copy.splice(index, 1);

  return copy;
}
