/**
 * Abbreviate specific words in an address string.
 *
 * @param {string} address - The address string to abbreviate.
 * @returns {string} The abbreviated address string with specified words replaced by their abbreviations.
 */
export default function abbreviate(address) {
  // Initialize the abbreviated address with the original address
  let abbreviatedAddress = address;

  // Define an array of word replacements with their abbreviations
  const replacements = [
    { word: "Avenida", abbreviation: "Av." },
    { word: "Pasaje", abbreviation: "Pje." },
    { word: "General", abbreviation: "Gral." },
    { word: "Edificio", abbreviation: "Edif." },
    { word: "Colonia", abbreviation: "Col." },
  ];

  // Loop through the replacements array
  for (let i = 0; i < replacements.length; i++) {
    const { word, abbreviation } = replacements[i];

    // Create a regular expression to match the word with word boundaries (\b) and 'g' flag for global replacement
    const regex = new RegExp(`\\b${word}\\b`, "g");

    // Replace all occurrences of the word with its abbreviation in the abbreviated address
    abbreviatedAddress = abbreviatedAddress.replace(regex, abbreviation);
  }

  return abbreviatedAddress;
}
