import { gql } from "@apollo/client";

const VALIDATE_DISCOUNT = gql`
  mutation ValidateCommuneDiscount($id: ID!) {
    validateCommuneDiscount(_id: $id) {
      _id
      discounts {
        subscription
      }
    }
  }
`;

export default VALIDATE_DISCOUNT;
