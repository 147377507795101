import { gql } from "@apollo/client";
import { EVENT_CORE, PROFILE_CORE } from "fragments";

const GET_EVENT = gql`
  query GetEvent($id: ID) {
    networkGetEvent(_id: $id) {
      ...EventCore
      config {
        _id
        active
        category
        feeType
        format
        isPublic
        interests {
          _id
          icon
          name
        }
        type
      }
      details {
        description
        summary
        itinerary
        includes
        bring
        restrictions
        important
        language
        firstSessionStartDate
        lastSessionEndDate
      }
      location {
        address
        city
        commune
        lat
        lng
        mapUrl
        name
        placeId
      }
      ratings {
        average
        userRatings {
          ref
          value
        }
      }
      sessionDates {
        _id
        endTime
        eventUrl
        meetUrl
        startTime
      }
      capacity
      minQuorum
      meetingPoint
      requiredAffiliation
      requiredServiceTier
      status
      updatedAt
      userLists {
        assistant {
          ...ProfileCore
        }
        interested {
          _id
        }
      }
    }
  }
  ${EVENT_CORE}
  ${PROFILE_CORE}
`;

export default GET_EVENT;
