import { FORM } from "consts";

/**
 * Sort two elements based on a specified property and sorting order.
 *
 * @param {any} elemA - The first element to compare.
 * @param {any} elemB - The second element to compare.
 * @param {string} sort - The property by which to sort.
 * @param {string} order - The sorting order ('asc' or 'desc').
 * @returns {number} A comparison result to determine the sorting order.
 */
export default function sortBy(elemA, elemB, sort, order) {
  return (
    (elemA?.[sort] > elemB?.[sort] ? 1 : -1) *
    (order === FORM.order.desc ? -1 : 1)
  );
}
