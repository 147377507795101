import { getClient } from "session/apolloClient";
import { APP_CONFIG } from "queries/session";
import { APP } from "consts";

/**
 * Fetches the application configuration from the GraphQL server.
 * Uses the Apollo Client to send a query for the application configuration.
 *
 * @returns {Promise<{ config: Object, loading: boolean, networkStatus: number }>}
 *   Returns a promise that resolves to an object containing the application configuration,
 *   loading status, and network status.
 */
export async function fetchConfig() {
  //"use server";

  try {
    // Query the application configuration using Apollo Client
    const { data, loading, networkStatus } = await getClient().query({
      query: APP_CONFIG,
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
    });

    // Extract the application configuration from the response data
    const config = data.getAppConfiguration || { company: APP };

    // Return the result as an object
    return {
      config,
      loading,
      networkStatus,
    };
  } catch (error) {
    // Handle errors if any occur during the configuration fetch
    console.error("Error fetching application configuration:", error);

    // Return an object with default values in case of an error
    return {
      config: { company: APP },
      loading: false,
      networkStatus: 0,
    };
  }
}
