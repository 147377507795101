import { gql } from "@apollo/client";

const EVENT_CORE = gql`
  fragment EventCore on Event {
    _id
    config {
      _id
      feeType
      format
      interests {
        _id
      }
      organization {
        _id
      }
      type
    }
    createdAt
    details {
      summary
      description
      firstSessionStartDate
      lastSessionEndDate
    }
    location {
      commune
      name
    }
    name
    photoUrl
    price
    sessionDates {
      _id
      endTime
      startTime
    }
    userLists {
      assistant {
        _id
      }
      interested {
        _id
      }
    }
  }
`;

export default EVENT_CORE;
