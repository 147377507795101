import moment from "moment";

/**
 * Convert a calendar date to a specific time format.
 *
 * @param {object} date - The calendar date object (e.g., from a library like moment.js).
 * @returns {string} The formatted time string (e.g., "HH:mm").
 */
export default function calendarToTime(date) {
  return moment(date.$d).format("HH:mm");
}
