import { gql } from "@apollo/client";

const POST_FIELDS = gql`
  fragment PostFields on Post {
    _id
    content {
      eventUrl
      message
      photoUrl
      videoUrl
    }
    createdAt
    from {
      _id
      info {
        firstName
        hasProfilePic
        lastName
      }
      status {
        isAdmin
        isPremium
      }
    }
    likes {
      _id
    }
    target
    updatedAt
  }
`;

export default POST_FIELDS;
