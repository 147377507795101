import normalize from "./normalize";

/**
 * Check if a string includes another string after normalizing them.
 *
 * @param {string} target - The target string to check.
 * @param {string} value - The value to search for.
 * @returns {boolean} True if the normalized target includes the normalized value; otherwise, false.
 */
export default function includes(target, value) {
  if (!target || !value) {
    return false;
  }

  // Normalize both the target and value for comparison
  const normalizedTarget = normalize(target);
  const normalizedValue = normalize(value);

  return normalizedTarget.includes(normalizedValue);
}
