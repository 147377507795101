/**
 * Normalize a text string by removing diacritics, special characters,
 * and converting spaces to hyphens while converting to lowercase.
 *
 * @param {string} text - The text string to normalize.
 * @returns {string} The normalized text.
 */
export default function normalize(text) {
  if (typeof text !== "string") {
    return "";
  }

  // Normalize the text by removing diacritics, special characters, and spaces
  const normalizedText = text
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
    .replace(/[^\w\s]|_/g, "") // Remove special characters and underscores
    .replace(/\s+/g, "-") // Convert spaces to hyphens
    .toLowerCase(); // Convert to lowercase

  return normalizedText;
}
