import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";

/**
 * Custom hook for using Apollo's useQuery with BUP's recommended setup.
 * @param {gql} query - The GraphQL query to run.
 * @param {Object} variables - The query variables to use.
 * @param {string} fetchPolicy - The fetch policy to use (default is 'cache-and-network').
 * @returns {object} - An object containing the query response properties.
 */
function useBupQuery(query, variables = {}, fetchPolicy = "cache-and-network") {
  // Create local state for the response object and a flag for whether the query has loaded.
  const [response, setResponse] = useState({});
  const [loaded, setLoaded] = useState(false);
  // Call useQuery with the provided query, variables, and fetch policy.
  const { called, data, loading, error } = useQuery(query, {
    fetchPolicy,
    variables,
  });

  // Use useEffect to update the response state and set the loaded flag when the query finishes loading.
  useEffect(() => {
    if (!loading) {
      if (data) {
        // The response data is an object with a single key containing the actual data we want to use.
        // Use Object.values() to extract the data object, and then get the first item in the array ([0]).
        setResponse(Object.values(data)?.[0]);
        setLoaded(true);
      }
      if (error) {
        console.log("error", error);
      }
    }
  }, [data, loading, error, query]);

  // Return the query response object.
  return { called, data: response, loading, loaded, error };
}

export default useBupQuery;
