import addHoursToDate from "./addHoursToDate";
import calculateAge from "./calculateAge";
import calendarToDate from "./calendarToDate";
import calendarToTime from "./calendarToTime";
import formatBirthday from "./formatBirthday";
import formatDateTime from "./formatDateTime";
import formatFullDate from "./formatFullDate";
import formatDayOfWeek from "./formatDayOfWeek";
import formatMonthYear from "./formatMonthYear";
import formatShortDate from "./formatShortDate";
import getAstrological from "./getAstrological";
import getCalendarDays from "./getCalendarDays";
import getDiffHours from "./getDiffHours";
import getLocaleTime from "./getLocaleTime";
import isUpcomingDate from "./isUpcomingDate";
import isValidDate from "./isValidDate";
import toDBDateTime from "./toDBDateTime";
import toLocaleDate from "./toLocaleDate";
import toReactElement from "./toReactElement";
import toShortTime from "./toShortTime";

const helpers = {
  addHoursToDate,
  calculateAge,
  calendarToDate,
  calendarToTime,
  formatBirthday,
  formatDateTime,
  formatFullDate,
  formatDayOfWeek,
  formatMonthYear,
  formatShortDate,
  getAstrological,
  getCalendarDays,
  getDiffHours,
  getLocaleTime,
  isUpcomingDate,
  isValidDate,
  toDBDateTime,
  toLocaleDate,
  toReactElement,
  toShortTime,
};

export default helpers;
