import Head from "next/head";
import { APP } from "consts";

function Title({ children, tag }) {
  return (
    <Head>
      <title>
        {children
          ? `${children} | ${tag ? `${tag} | ` : ""}${APP.alias}`
          : `${tag ? `${tag} | ` : ""}${APP.alias}`}
      </title>
    </Head>
  );
}

export default Title;
