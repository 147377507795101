import { gql } from "@apollo/client";

const GET_USER_POSTS = gql`
  query GetPosts($from: ID) {
    getPosts(from: $from) {
      total
    }
  }
`;

export default GET_USER_POSTS;
