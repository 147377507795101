import { gql } from "@apollo/client";
import { EVENT_CORE } from "fragments";

const GET_SUGGEST_EVENTS = gql`
  query GetSuggestEvents($limit: Int) {
    getUserEventSuggestions(limit: $limit) {
      total
      instances {
        ...EventCore
      }
    }
  }
  ${EVENT_CORE}
`;

export default GET_SUGGEST_EVENTS;
