/**
 * Capitalize text with options to capitalize the first letter of each word.
 *
 * @param {string} text - The input text to capitalize.
 * @param {boolean} capitalizeAllWords - Whether to capitalize all words.
 * @param {boolean} isEvent - Whether the text is an event title.
 * @returns {string} The capitalized text.
 */
export default function capitalize(
  text,
  capitalizeAllWords = false,
  isEvent = false
) {
  if (!text) {
    return "";
  }

  if (!capitalizeAllWords) {
    // Capitalize only the first letter of the text
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }

  if (isEvent) {
    // Capitalize an event title with specific rules
    return capitalizeEvent(text);
  }

  // Capitalize all words in the text
  return text
    .split(/[\s-]+/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

/**
 * Capitalize an event title with specific rules.
 *
 * @param {string} text - The event title to capitalize.
 * @returns {string} The capitalized event title.
 */
function capitalizeEvent(text) {
  if (!text) return "";

  // Handle special characters and formatting
  const formattedText = text.replace(/[\u201C\u201D\u2018\u2019]/g, '"');

  const words = formattedText.split(/[\s-]+/);
  const connectors = [
    "a",
    "al",
    "ante",
    "bajo",
    "con",
    "contra",
    "de",
    "del",
    "desde",
    "días",
    "durante",
    "el",
    "en",
    "es",
    "entre",
    "hacia",
    "hasta",
    "la",
    "las",
    "los",
    "mi",
    "mis",
    "o",
    "para",
    "pero",
    "por",
    "sin",
    "sobre",
    "tras",
    "tu",
    "tus",
    "ver",
    "y",
  ];

  const capitalizedWords = words.map((word, index) => {
    if (connectors.includes(word.toLowerCase())) {
      // Leave connectors in lowercase
      return word.toLowerCase();
    }
    if (word.match(/^(bondup[.?:!]?$|bondup)$/i)) {
      // Handle specific cases for "BondUP"
      const punctuation = word.match(/[.?:!]?$/)[0];
      return "BondUP" + punctuation;
    }
    if (word.match(/^[¿¡]/)) {
      // Handle words starting with "¿" or "¡"
      return word.charAt(0) + word.charAt(1).toUpperCase() + word.slice(2);
    }
    if (
      index > 0 &&
      formattedText.charAt(formattedText.indexOf(word) - 1) === '"'
    ) {
      // Handle words following a double quotation mark
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
    if (word.startsWith('"')) {
      // Handle words starting with a double quotation mark
      return '"' + word.charAt(1).toUpperCase() + word.slice(2);
    }
    // Default: Capitalize the first letter of the word
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  // Capitalize the first letter of the text
  let capitalizedText = capitalizedWords.join(" ");
  capitalizedText =
    capitalizedText.charAt(0).toUpperCase() +
    (capitalizedText.charAt(0).match(/^[¿¡]/)
      ? capitalizedText.charAt(1).toUpperCase() + capitalizedText.slice(2)
      : capitalizedText.slice(1));

  return capitalizedText;
}
